import { globalAssets } from "../../globals/globalAssets"
import { sharedDireccion } from "./sharedDireccion"
import { utilDate } from "../../utils/utilDate"
import { sharedPersonasVehiculos } from "./sharedPersonasVehiculos"
import { sharedPersonasPosteos } from "./sharedPersonasPosteos"
import { sharedPersonaSeguidor } from "./sharedPersonaSeguidor"
import { sharedPersonasModalidades } from "./sharedPersonasModalidades"

export namespace sharedPersonaPrivacidad {
	export interface IShared {
		campo: string
		valor: number
	}

	export const sharedEnumTipoPrivacidad = {
		Privado: 0,
		SoloAmigos: 1,
		Publico: 2,
	}

	export const getShared_FromJson = (privacidadJson: string) => {
		const res: IShared[] = []

		try {
			const json = JSON.parse(privacidadJson)

			for (const key in json) {
				const campo = json[key].campo
				const valor = json[key].valor

				// valor puede ser 0, por eso pongo !== undefined y !== null y no solo !
				if (campo && valor !== undefined && valor !== null) {
					res.push({ campo, valor })
				}
			}
		} catch (error) {
			console.log("getShared_FromJson error", error)
		}

		return res
	}

	export const getJson_FromSharedList = (sharedList: IShared[]) => {
		try {
			const res = JSON.stringify(sharedList)
			return res
		} catch (error) {
			console.log("getJson_FromSharedList error", error)
		}
	}

	export const getPrivacidadDeUnCampo = (sharedList: IShared[], campo: string) => {
		const shared = sharedList.find((shared) => {
			return shared.campo === campo
		})

		if (shared) {
			return shared.valor
		}
		return sharedEnumTipoPrivacidad.Publico // por default es publico
	}

	export const upsertAndGetPrivacidadDeUnCampo = (sharedList: IShared[], campo: string, valor: number) => {
		var tempSharedList = [...sharedList]

		const indiceEncontrado = sharedList.findIndex((shared) => shared.campo === campo)

		const newPrivacidad: IShared = { campo, valor }

		if (indiceEncontrado !== -1) {
			tempSharedList[indiceEncontrado] = newPrivacidad
		} else {
			tempSharedList.push(newPrivacidad)
		}

		return tempSharedList
	}

	export const upsertAndGetJsonPrivacidadDeUnCampo = (sharedList: IShared[], campo: string, valor: number) => {
		console.clear()

		const privacidadActualizada = upsertAndGetPrivacidadDeUnCampo(sharedList, campo, valor)
		const privacidadActualizadaJson = getJson_FromSharedList(privacidadActualizada)
		if (!privacidadActualizadaJson) return ""
		return privacidadActualizadaJson
	}

	export const puedeMostrarCampo = (sharedList: IShared[], campo: string) => {
		const privacidad = getPrivacidadDeUnCampo(sharedList, campo)

		if (privacidad === sharedEnumTipoPrivacidad.Privado) {
			return false
		}

		return true
	}
}
