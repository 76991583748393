import { globalAssets } from "../../globals/globalAssets"
import { sharedDireccion } from "./sharedDireccion"
import { utilDate } from "../../utils/utilDate"
import { sharedPersonasVehiculos } from "./sharedPersonasVehiculos"
import { sharedPersonasPosteos } from "./sharedPersonasPosteos"
import { sharedPersonaSeguidor } from "./sharedPersonaSeguidor"
import { sharedPersonasModalidades } from "./sharedPersonasModalidades"
import { sharedPersonaPrivacidad } from "./sharedPersonaPrivacidad"

export namespace sharedPersona {
	export interface IShared {
		id: number
		nombre: string
		apellido1: string
		apellido2: string
		alias: string
		avatarURL: string
		coverURL: string
		fechaNacimiento: Date | null
		dni: string
		dniVerificado: boolean | null
		idTipoSexo: number | null
		direccion: sharedDireccion.IShared | null
		descripcionCorta: string
		descripcionLarga: string
		telefonoMovil: string
		email: string
		linkInstagram: string
		linkFacebook: string
		linkLinkedIn: string
		linkTwitter: string
		cantidadSeguidores: number
		cantidadSiguiendo: number
		cantidadAmigos: number
		isProfileCompleted: boolean
		vehiculos: sharedPersonasVehiculos.IShared[]
		modalidades: sharedPersonasModalidades.IShared[]
		posteos: sharedPersonasPosteos.IShared[]
		privacidadList: sharedPersonaPrivacidad.IShared[]
		// personasSeguidores: sharedPersonaSeguidor.IShared[]
	}

	export const camposParaPrivacidad = {
		email: "email",
		telefonoMovil: "telefonoMovil",
	}

	export const objectDefault: IShared = {
		id: 0,
		nombre: "",
		apellido1: "",
		apellido2: "",
		alias: "",
		avatarURL: globalAssets.staticPaths.personaAvatar,
		coverURL: globalAssets.staticPaths.personaCover1,
		fechaNacimiento: null,
		dni: "",
		dniVerificado: null,
		idTipoSexo: null,
		direccion: null,
		descripcionCorta: "",
		descripcionLarga: "",
		telefonoMovil: "",
		email: "",
		linkInstagram: "",
		linkFacebook: "",
		linkLinkedIn: "",
		linkTwitter: "",
		cantidadSeguidores: 0,
		cantidadSiguiendo: 0,
		cantidadAmigos: 0,
		isProfileCompleted: false,
		vehiculos: [],
		modalidades: [],
		posteos: [],
		privacidadList: [],

		// personasSeguidores: [],
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...objectDefault,
			...apiObject,
			fechaNacimiento: utilDate.fDateFromNet(apiObject.fechaNacimiento),
			direccion: apiObject.direccion && sharedDireccion.getSharedOne_FromApiObject(apiObject.direccion),
			avatarURL: apiObject.avatarURL || globalAssets.staticPaths.personaAvatar,
			coverURL: apiObject.coverURL || globalAssets.staticPaths.personaCover1,
			vehiculos: apiObject.vehiculos && sharedPersonasVehiculos.getSharedList_FromApiObject(apiObject.vehiculos),
			modalidades:
				apiObject.modalidades && sharedPersonasModalidades.getSharedList_FromApiObject(apiObject.modalidades),
			posteos: apiObject.posteos && sharedPersonasPosteos.getSharedList_FromApiObject(apiObject.posteos),
			// personasSeguidores:
			// 	apiObject.personasSeguidores && sharedPersonaSeguidor.getSharedList_FromApiObject(apiObject.personasSeguidores),
			cantidadSeguidores: apiObject.cantidadSeguidores || 0,
			privacidadList:
				(apiObject.privacidadJson && sharedPersonaPrivacidad.getShared_FromJson(apiObject.privacidadJson)) || [],
		}

		return res
	}
}
