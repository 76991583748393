import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Login_GetLoginResForUserLogged } from "../../api/calls/IG/Login/apiCallIG_Login_GetLoginResForUserLogged"
import { apiCallIG_Login_LoginWithEmail } from "../../api/calls/IG/Login/apiCallIG_Login_LoginWithEmail"
import { apiCallIG_Login_LoginWithFacebook } from "../../api/calls/IG/Login/apiCallIG_Login_LoginWithFacebook"
import { apiCallIG_Login_LoginWithGoogle } from "../../api/calls/IG/Login/apiCallIG_Login_LoginWithGoogle"
import { sharedLoginRes } from "../../api/shared/sharedLoginRes"
import { utilLogger } from "../../utils/utilLogger"
import { utilRedux } from "../../utils/utilRedux"
import { utilUser } from "../../utils/utilUser"
import { bllGlobalsUI_FullScreenLoading } from "../GlobalsUI/bllGlobalsUI_FullScreenLoading"
import { bllGlobalsUser_CurrentPersona } from "../GlobalsUser/bllGlobalsUser_CurrentPersona"
import { bllGlobalsUser_Personas } from "../GlobalsUser/bllGlobalsUser_Personas"
import { bllGlobalsUser_Roles } from "../GlobalsUser/bllGlobalsUser_Roles"

export namespace bllUsers_Login {
	//ET = enumTypes
	enum ET {
		setInitialState = "Users_Login_setInitialState",
		setLoginRes = "Users_Login_setLoginRes",
		loginWithEmailStart = "Users_Login_loginWithEmailStart",
		logout = "Users_Login_Logout",
		loginFromStorageIfItHasLoginResStart = "Users_Login_loginFromStorageIfItHasLoginResStart",
		loginFromStorageIfItHasLoginResChecked = "User_Login_loginFromStorageIfItHasLoginResChecked",
		refresh = "Users_Login_refresh",
	}

	export interface IInterface {
		storageSharedLoginResChecked: boolean
		isLogged: boolean
		loginRes: sharedLoginRes.IShared
		redirectUrl: string | null
	}

	const getInitialState = (): IInterface => {
		return {
			storageSharedLoginResChecked: false,
			isLogged: false,
			loginRes: sharedLoginRes.objectDefault,
			redirectUrl: null,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.User_Login_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loginFromStorageIfItHasLoginRes(apiCallParams: apiCallInterface) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.loginFromStorageIfItHasLoginResStart, {})

			const loginRes = utilUser.getStorageLoginRes()

			if (loginRes) {
				D(setLoginRes(apiCallParams, loginRes))
			} else {
				type actionSubType = Pick<IInterface, "storageSharedLoginResChecked">
				const updateObject: actionSubType = {
					storageSharedLoginResChecked: true,
				}

				utilRedux.TA(D, ET.loginFromStorageIfItHasLoginResChecked, updateObject)
			}
		}
	}

	export function setLoginRes(apiCallParams: apiCallInterface, loginRes: sharedLoginRes.IShared) {
		//D = dispatch
		return async (D: any) => {
			// await D(actionUser_Login_SetInitialState()) //Acá no seteo el initialstate porque si no, en el refrescar, la SharedPage por un segundo asume que no estoy logueado y me redirige a la home
			type actionSubType = Pick<IInterface, "storageSharedLoginResChecked" | "isLogged" | "loginRes">
			const updateObject: actionSubType = {
				storageSharedLoginResChecked: true,
				isLogged: true,
				loginRes: loginRes,
			}

			utilRedux.TA(D, ET.setLoginRes, updateObject)

			await D(bllGlobalsUser_Roles.setRoles(loginRes.roles))

			utilUser.setStorageLoginRes(loginRes)
		}
	}

	export function loginWithEmail(apiCallParams: apiCallInterface, params: apiCallIG_Login_LoginWithEmail.params) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.loginWithEmailStart, {})

			const apiCallRes = await apiCallIG_Login_LoginWithEmail.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(setLoginRes(apiCallParams, apiCallRes.sharedLoginRes))
			}
		}
	}

	export function loginWithGoogle(apiCallParams: apiCallInterface, params: apiCallIG_Login_LoginWithGoogle.params) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.loginWithEmailStart, {})

			const apiCallRes = await apiCallIG_Login_LoginWithGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(setLoginRes(apiCallParams, apiCallRes.sharedLoginRes))
			}
		}
	}

	export function loginWithFacebook(apiCallParams: apiCallInterface, params: apiCallIG_Login_LoginWithFacebook.params) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.loginWithEmailStart, {})

			const apiCallRes = await apiCallIG_Login_LoginWithFacebook.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(setLoginRes(apiCallParams, apiCallRes.sharedLoginRes))
			}
		}
	}

	export function logout() {
		//D = dispatch
		return async (D: any) => {
			utilUser.logout()

			D(setInitialState())
			D(bllGlobalsUser_CurrentPersona.setInitialState())
			D(bllGlobalsUser_Personas.setInitialState())
		}
	}

	export function refresh(apiCallParams: apiCallInterface) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Login_GetLoginResForUserLogged.apiCall(apiCallParams)

			if (apiCallRes) {
				D(setLoginRes(apiCallParams, apiCallRes.sharedLoginRes))
			}
		}
	}
}
