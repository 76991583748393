export namespace globalConfig {
	type Environment = "development" | "staging" | "production"
	type ApiCallEnvironment = "toStaging" | "LaptopFerIIS" | "LaptopFerNet" | "fromDesktopFer" | "toProduction"

	//Staging
	// export const environment: Environment = "staging"
	// export const apiCallEnvironmentGeneral: ApiCallEnvironment = "toStaging"
	// export const apiCallEnvironmentMotopoliza: ApiCallEnvironment = "toStaging"
	// export const apiCallEnvironmentSaludOnNet: ApiCallEnvironment = "toStaging"
	// export const apiCallEnvironmentSecurity: ApiCallEnvironment = "toStaging"
	// export const apiCallEnvironmentPruebas: ApiCallEnvironment = "toStaging"

	//LaptopFerIIS
	// export const environment: Environment = "development"
	// export const apiCallEnvironmentGeneral: ApiCallEnvironment = "LaptopFerNet"
	// export const apiCallEnvironmentMotopoliza: ApiCallEnvironment = "LaptopFerIIS"
	// export const apiCallEnvironmentSaludOnNet: ApiCallEnvironment = "LaptopFerIIS"
	// export const apiCallEnvironmentSecurity: ApiCallEnvironment = "LaptopFerIIS"
	// export const apiCallEnvironmentPruebas: ApiCallEnvironment = "LaptopFerIIS"

	//Producción
	export const environment: Environment = "production"
	export const apiCallEnvironmentGeneral: ApiCallEnvironment = "toProduction"
	export const apiCallEnvironmentMotopoliza: ApiCallEnvironment = "toProduction"
	export const apiCallEnvironmentSaludOnNet: ApiCallEnvironment = "toProduction"
	export const apiCallEnvironmentSecurity: ApiCallEnvironment = "toProduction"
	export const apiCallEnvironmentPruebas: ApiCallEnvironment = "toProduction"
	//https://api-***.motopoliza.com

	//----------------------------------------
	// frontendUrl
	//----------------------------------------
	const get_frontendUrlWithoutEndSlash = (p_environment: Environment): string => {
		switch (p_environment) {
			case "development":
				return "http://localhost:3000"
			case "staging":
				return "https://cir.stapp.motopoliza.com"
			case "production":
				return "https://app.motopoliza.com"
			default:
				return ""
		}
	}

	export const frontendUrlWithoutEndSlash = get_frontendUrlWithoutEndSlash(environment)
	export const frontendUrl = frontendUrlWithoutEndSlash + "/"
	//----------------------------------------

	//----------------------------------------
	// staticsPathsBaseUrl
	//----------------------------------------
	const get_staticsPathsBaseUrl = (p_environment: Environment): string => {
		switch (p_environment) {
			case "development":
				return frontendUrl + "staticsToUpload/"
			case "staging":
				return "https://pruebamp.s3.eu-west-3.amazonaws.com/estaticas/"
			case "production":
				return "https://appmotopolizabucket.s3.amazonaws.com/statics/"
			default:
				return ""
		}
	}

	export const staticsPathsBaseUrl = get_staticsPathsBaseUrl(environment)
	//----------------------------------------

	//----------------------------------------
	// apiPathBaseGeneral
	//----------------------------------------
	const get_apiPathBaseGeneral = (p_apiCallEnvironment: ApiCallEnvironment): string => {
		switch (p_apiCallEnvironment) {
			case "toStaging":
				return "https://gen.stapi.motopoliza.com/api/v1"
			case "LaptopFerIIS":
				return "https://localhost/inxur/general/api/v1"
			case "LaptopFerNet":
				return "https://localhost:44310/api/v1"
			case "fromDesktopFer":
				return "https://192.168.68.51/inxur/general/api/v1"
			case "toProduction":
				return "https://api-gen.motopoliza.com/api/v1"
			default:
				return ""
		}
	}

	export const apiPathBaseGeneral = get_apiPathBaseGeneral(apiCallEnvironmentGeneral)
	//----------------------------------------

	//----------------------------------------
	// apiPathBaseMotopoliza
	//----------------------------------------
	const get_apiPathBaseMotopoliza = (p_apiCallEnvironment: ApiCallEnvironment): string => {
		switch (p_apiCallEnvironment) {
			case "toStaging":
				return "https://mp.stapi.motopoliza.com/api/v1"
			case "LaptopFerIIS":
				return "https://localhost/inxur/motopoliza/api/v1"
			case "LaptopFerNet":
				return "https://localhost:7061/api/v1"
			case "fromDesktopFer":
				return "https://192.168.68.51/inxur/general/api/v1"
			case "toProduction":
				return "https://api-mp.motopoliza.com/api/v1"
			// case "fromLaptopNetFer":
			// return "https://localhost:7061/api/v1"
			default:
				return ""
		}
	}

	export const apiPathBaseMotopoliza = get_apiPathBaseMotopoliza(apiCallEnvironmentMotopoliza)
	//----------------------------------------

	//----------------------------------------
	// apiPathBaseSaludOnNet
	//----------------------------------------
	const get_apiPathBaseSaludOnNet = (p_apiCallEnvironment: ApiCallEnvironment): string => {
		switch (p_apiCallEnvironment) {
			case "toStaging":
				return "https://son.stapi.motopoliza.com/api/v1"
			case "LaptopFerIIS":
				return "https://localhost/inxur/saludonnet/api/v1"
			case "LaptopFerNet":
				return "sssssssss"
			case "fromDesktopFer":
				return "https://192.168.68.51/inxur/saludonnet/api/v1"
			case "toProduction":
				return "https://api-son.motopoliza.com/api/v1"
			// case "fromLaptopNetFer":
			// 	return "ttttttt"
			default:
				return ""
		}
	}

	export const apiPathBaseSaludOnNet = get_apiPathBaseSaludOnNet(apiCallEnvironmentSaludOnNet)
	//----------------------------------------

	//----------------------------------------
	// apiPathBaseSecurity
	//----------------------------------------
	const get_apiPathBaseSecurity = (p_apiCallEnvironment: ApiCallEnvironment): string => {
		switch (p_apiCallEnvironment) {
			case "toStaging":
				return "https://sec.stapi.motopoliza.com/api/v1"
			case "LaptopFerIIS":
				return "https://localhost/inxur/security/api/v1"
			case "LaptopFerNet":
				return "eeeeeeeeeeeee"
			case "fromDesktopFer":
				return "https://192.168.68.51/inxur/general/api/v1"
			case "toProduction":
				return "https://api-sec.motopoliza.com/api/v1"
			// case "fromLaptopNetFer":
			// 	return "https://localhost:44392/api/v1"
			default:
				return ""
		}
	}

	export const apiPathBaseSecurity = get_apiPathBaseSecurity(apiCallEnvironmentSecurity)
	//----------------------------------------

	//----------------------------------------
	// apiPathBasePruebas
	//----------------------------------------
	const get_apiPathBasePruebas = (p_apiCallEnvironment: ApiCallEnvironment): string => {
		switch (p_apiCallEnvironment) {
			case "toStaging":
				return "https://pruebas.stapi.motopoliza.com/api/v1"
			case "LaptopFerIIS":
				return "https://localhost/inxur/pruebas/api/v1"
			case "LaptopFerNet":
				return "pppppppppp"
			case "fromDesktopFer":
				return "https://192.168.68.51/inxur/pruebas/api/v1"
			case "toProduction":
				return "https://api-pruebas.motopoliza.com/api/v1"
			// case "fromLaptopNetFer":
			// 	return "pppppppp"
			default:
				return ""
		}
	}

	export const apiPathBasePruebas = get_apiPathBasePruebas(apiCallEnvironmentPruebas)
	//----------------------------------------

	export const appVersion = "1.0"
	export const cookieFirstKey = "1eb79b5f-8ffc-43be-be15-708b8a73b748"

	//----------------------------------------
	// aws
	//----------------------------------------
	interface IAws {
		accessKeyId: string
		secretAccessKey: string
		region: string
		bucket: string
	}

	const get_aws = (p_environment: Environment): IAws => {
		switch (p_environment) {
			case "development":
				return {
					accessKeyId: "AKIAZVCKV2I7M2ZDSHEO",
					secretAccessKey: "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",
					region: "eu-west-1",
					bucket: "appmotopolizabucket",
				}
			//esta funciona:
			// return {
			// 	accessKeyId: "AKIAUC44P2YNQ6ACGF62",
			// 	secretAccessKey: "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",
			// 	region: "eu-west-3",
			// 	bucket: "pruebamp",
			// }
			case "staging":
				return {
					accessKeyId: "AKIAZVCKV2I7M2ZDSHEO",
					secretAccessKey: "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",
					region: "eu-west-1",
					bucket: "appmotopolizabucket",
				}
			case "production":
				return {
					accessKeyId: "AKIAZVCKV2I7M2ZDSHEO",
					secretAccessKey: "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",
					region: "eu-west-1",
					bucket: "appmotopolizabucket",
				}
			default:
				return {
					accessKeyId: "AKIAZVCKV2I7M2ZDSHEO",
					secretAccessKey: "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",
					region: "eu-west-1",
					bucket: "appmotopolizabucket",
				}
		}
	}

	export const aws = get_aws(environment)

	export const appName = "Motopóliza"
	export const googlePlacesApiKey = "AIzaSyAL2vDKs6jxXPYZj5gAKwcJxAv-oF9w4hU"
	export const googleClientIdForLogin = "809460640715-jsgjpptntuaeppnt1cfrv9adgip9p97a.apps.googleusercontent.com"
}
